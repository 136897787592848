.homeBar {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.home-bar-contents {
  justify-content: flex-end;
}

.home-bar-brand {
  position: absolute;
  left: 75px;
}

.home-bar-logo {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 1em;
}

.searchBox {
  display: flex;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
}

.searchIcon {
  align-items: center;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
}

.searchInput {
  color: #fefefe !important;
}

.person {
  display: flex;
  align-items: center;
  margin-left: 1.5em;
}

.person-image {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-size: 14px;
}

#home-backButton {
  color: #fefefe !important;
  text-decoration: none;
}
