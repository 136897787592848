
.pixbox {
  width: 80%;
  margin: auto;
}

.contending-photo {
  width: 100%;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.38);
}

.contending-photo-portrait {
  width: 65%;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.38);
}

.star-group {
  margin-top: 1em;
}

.star {
  min-width: 20px;
  width: 5%;
  margin: 0 2px;
  max-width: 40px;
}