
.scoreCard {
  width: 85%;
  margin: 2% auto;
  text-align: left;
}

.scoreCard-title {
  font-family: inherit !important;
  font-size: 1.2em !important;
  position: relative;
  margin-bottom: 30px !important;
}

.title-date {
  position: absolute;
  right: 0px;
  font-size: 0.85em;
}

.scoreCard-scores {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.scoreDivide {
  border: 0.5px solid rgba(0,0,0,0.2);
}

.score {
  padding: 1em;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.score-owner {
  margin-top: 14px;
  font-weight: normal;
  font-size: 0.8em;
}

.table-background {
  width: 85%;
  margin: auto;
}

.table-total {
  font-weight: bold !important;
}


.add-contest-form {
  margin: 5% 1%;
}

.newContestForm {
  display: flex;
  flex-flow: column;
}

.contest-input-full {
  width: 100%;
}

.contest-input-half {
  width: 40%;
  margin-right: 10px !important;
}

.half-box {

}

.contest-action-buttons {
  text-align: right;
}