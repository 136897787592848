.home-header {
  margin: 2% auto;
  width: 85%;
}

.home-header-subtitle {
  text-align: left;
  margin: auto;
  color: #f0f8ff;
}

.contest-list-container {
  width: 85%;
  margin: auto;
}

.contest-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.contest-list-item {
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 5px;
  margin: auto;
  margin-bottom: 1em;
  padding: 1.1em !important;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.12);
}

.contest-card {
  background-color: rgba(16, 29, 66, 0.29) !important;
  border-radius: 5px !important;
  margin: 1.1em;
  margin-bottom: 1em;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.12) !important;
  max-width: 40%;
  min-width: 370px;
  text-align: left;
  flex: 1;
}

.contest-card-img {
  width: 100%;
}

.contest-card-title {
  color: #f0f8ff !important;
  font-size: 22px !important;
  font-family: inherit !important;
}

.contest-card-desc {
  color: rgba(255,205,205,0.96) !important;
  font-family: inherit !important;
}


.contest-list-loader {
  color: #dcdeec !important;
  width: 65px !important;
  height: 65px !important;
}