

.results-card {
  width: 80%;
  margin: 3% auto;
  padding: 1.5em;
  border-radius: 5px;
}

.results-card-header {
  font-size: 24px;
  font-weight: bolder;
  border-bottom: 2px solid #fefefe;
  padding-bottom: 15px;
  text-align: left;
  color: #f0f8ff
}

.results-card-body {
  margin: 5% auto;
  color: #f0f8ff;
}

.results-card-subheading {
  color: #f0f8ff;
  text-align: left;
  font-size: 18px;
}

.results-score {
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: crimson;
}

.victory-card {
  margin-top: 2%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner {
  position: absolute;
  bottom: 1px;
  font-weight: bold;
  font-size: 30px;
}

.winner-image {
  width: 25em;
  max-width: 400px;
}

.icon-action {
  font-size: 2em !important;
}