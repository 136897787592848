
html {
  background-color: #333540;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.pixbox {
  width: 80%;
  margin: auto;
}

.contending-photo {
  width: 100%;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.38);
}

.contending-photo-portrait {
  width: 65%;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.38);
}

.star-group {
  margin-top: 1em;
}

.star {
  min-width: 20px;
  width: 5%;
  margin: 0 2px;
  max-width: 40px;
}
.homeBar {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0))) !important;
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.home-bar-contents {
  justify-content: flex-end;
}

.home-bar-brand {
  position: absolute;
  left: 75px;
}

.home-bar-logo {
  height: 40px;
  width: 40px;
  position: absolute;
  left: 1em;
}

.searchBox {
  display: flex;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 3px;
}

.searchIcon {
  align-items: center;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
}

.searchInput {
  color: #fefefe !important;
}

.person {
  display: flex;
  align-items: center;
  margin-left: 1.5em;
}

.person-image {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-size: 14px;
}

#home-backButton {
  color: #fefefe !important;
  text-decoration: none;
}


.remaining-rounds {
  text-align: right;
  width: 85%;
  margin: 1em auto;
  font-style: italic;
  color: #c3c2c2;
}

.round-count {
  font-weight: bold;
  color: #fefefe;
}

.series-title {
  margin-bottom: 1em;
  font-size: 1.1em;
  color: rgba(255, 255, 255, 0.85);
}

.series-subtitle {
  color: rgba(225, 241, 255, 0.6);
}

.contest-body {
  display: flex;
  justify-content: center;
  margin: 2% auto;
}

.contest-entry-divider {
  width: 0px;
  border: rgba(243,243,243,0.14) 0.5px solid;
}

.contest-entry {
  width: 50%;
  margin: auto;
}

.hidden {
  display: none;
}

.actionButton-container {
  text-align: right;
  width: 90%;
  margin: auto;
}

.next-button {
  color: #c3c2c2;
  font-size: 1.5em;
}

.next-button-wrap {
  border: solid white 1px !important;
}

.login-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}

.login-logo {
  width: 150px;
  height: 150px;
}

.brand-card {
  margin: 0 5%;
}

.login-card {
  margin: 0 5%;
}
.home-header {
  margin: 2% auto;
  width: 85%;
}

.home-header-subtitle {
  text-align: left;
  margin: auto;
  color: #f0f8ff;
}

.contest-list-container {
  width: 85%;
  margin: auto;
}

.contest-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.contest-list-item {
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 5px;
  margin: auto;
  margin-bottom: 1em;
  padding: 1.1em !important;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.12);
}

.contest-card {
  background-color: rgba(16, 29, 66, 0.29) !important;
  border-radius: 5px !important;
  margin: 1.1em;
  margin-bottom: 1em;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.12) !important;
  max-width: 40%;
  min-width: 370px;
  text-align: left;
  flex: 1 1;
}

.contest-card-img {
  width: 100%;
}

.contest-card-title {
  color: #f0f8ff !important;
  font-size: 22px !important;
  font-family: inherit !important;
}

.contest-card-desc {
  color: rgba(255,205,205,0.96) !important;
  font-family: inherit !important;
}


.contest-list-loader {
  color: #dcdeec !important;
  width: 65px !important;
  height: 65px !important;
}

.scoreCard {
  width: 85%;
  margin: 2% auto;
  text-align: left;
}

.scoreCard-title {
  font-family: inherit !important;
  font-size: 1.2em !important;
  position: relative;
  margin-bottom: 30px !important;
}

.title-date {
  position: absolute;
  right: 0px;
  font-size: 0.85em;
}

.scoreCard-scores {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.scoreDivide {
  border: 0.5px solid rgba(0,0,0,0.2);
}

.score {
  padding: 1em;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.score-owner {
  margin-top: 14px;
  font-weight: normal;
  font-size: 0.8em;
}

.table-background {
  width: 85%;
  margin: auto;
}

.table-total {
  font-weight: bold !important;
}


.add-contest-form {
  margin: 5% 1%;
}

.newContestForm {
  display: flex;
  flex-flow: column;
}

.contest-input-full {
  width: 100%;
}

.contest-input-half {
  width: 40%;
  margin-right: 10px !important;
}

.half-box {

}

.contest-action-buttons {
  text-align: right;
}


.results-card {
  width: 80%;
  margin: 3% auto;
  padding: 1.5em;
  border-radius: 5px;
}

.results-card-header {
  font-size: 24px;
  font-weight: bolder;
  border-bottom: 2px solid #fefefe;
  padding-bottom: 15px;
  text-align: left;
  color: #f0f8ff
}

.results-card-body {
  margin: 5% auto;
  color: #f0f8ff;
}

.results-card-subheading {
  color: #f0f8ff;
  text-align: left;
  font-size: 18px;
}

.results-score {
  font-weight: bold;
  text-decoration: underline;
  -webkit-text-decoration-color: crimson;
          text-decoration-color: crimson;
}

.victory-card {
  margin-top: 2%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner {
  position: absolute;
  bottom: 1px;
  font-weight: bold;
  font-size: 30px;
}

.winner-image {
  width: 25em;
  max-width: 400px;
}

.icon-action {
  font-size: 2em !important;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

