
.login-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}

.login-logo {
  width: 150px;
  height: 150px;
}

.brand-card {
  margin: 0 5%;
}

.login-card {
  margin: 0 5%;
}