
.remaining-rounds {
  text-align: right;
  width: 85%;
  margin: 1em auto;
  font-style: italic;
  color: #c3c2c2;
}

.round-count {
  font-weight: bold;
  color: #fefefe;
}

.series-title {
  margin-bottom: 1em;
  font-size: 1.1em;
  color: rgba(255, 255, 255, 0.85);
}

.series-subtitle {
  color: rgba(225, 241, 255, 0.6);
}

.contest-body {
  display: flex;
  justify-content: center;
  margin: 2% auto;
}

.contest-entry-divider {
  width: 0px;
  border: rgba(243,243,243,0.14) 0.5px solid;
}

.contest-entry {
  width: 50%;
  margin: auto;
}

.hidden {
  display: none;
}

.actionButton-container {
  text-align: right;
  width: 90%;
  margin: auto;
}

.next-button {
  color: #c3c2c2;
  font-size: 1.5em;
}

.next-button-wrap {
  border: solid white 1px !important;
}